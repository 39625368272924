/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.5.1/dist/jquery.min.js
 * - /npm/popper.js@1.16.1/dist/umd/popper.min.js
 * - /npm/bootstrap@4.5.3/dist/js/bootstrap.min.js
 * - /npm/bootstrap-datepicker@1.9.0/js/bootstrap-datepicker.min.js
 * - /npm/bootstrap-datepicker@1.9.0/js/locales/bootstrap-datepicker.de.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
